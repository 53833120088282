import { useApi } from '@tenant/composables'

export type ContactTypesState = {
    contactTypes: string[]
}

export default {
    state: (): ContactTypesState => ({
        contactTypes: [],
    }),

    mutations: {
        setContactTypes(state: ContactTypesState, contactTypes: []) {
            state.contactTypes = contactTypes
        },
    },

    getters: {
        contactTypes(state: ContactTypesState) {
            return state.contactTypes
        },
    },

    actions: {
        fetchContactTypes({ commit }: { commit: (...args: any[]) => void }) {
            const { execute } = useApi('/api/contact-types', 'GET')

            execute().then((response) => {
                const contactTypes = response.map(
                    (salutation: { id: string; name: string }) => ({
                        value: salutation.id,
                        label: salutation.name,
                    })
                )

                commit('setContactTypes', contactTypes)
            })
        },
    },
}
